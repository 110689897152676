import {
  useMutation,
  useQueryClient,
  type UseQueryReturnType,
} from '@tanstack/vue-query'
import { useQuery } from '@tanstack/vue-query'
import type { MaybeRef, Ref, UnwrapRef } from 'vue'
import { FetchError } from 'ofetch'
import type { components } from '~/schema'
import { AccommodationFullDetailsType } from '~/schema'

export type AccommodationId = string

export type AccommodationFullDetails =
  components['schemas']['AccommodationFullDetails']

type HouseLicenseUpdate = components['schemas']['UpdateHousingLicenseRequest']

export { AccommodationFullDetailsType as AccommodationType }

type UseAccommodationQueryResult = UseQueryReturnType<
  AccommodationFullDetails,
  FetchError
>

export function useAccommodationQuery<
  Suspense extends boolean | undefined,
  Result extends [Suspense] extends [true]
    ? Promise<UseAccommodationQueryResult>
    : UseAccommodationQueryResult,
>(
  accommodationId: MaybeRef<AccommodationId | undefined>,
  {
    enabled,
    suspense,
  }: { enabled?: MaybeRef<boolean>; suspense?: Suspense } = {},
): Result {
  const fetch = useApiFetch()
  const queryResult = useQuery<AccommodationFullDetails, FetchError>({
    enabled: computed(() => unref(enabled) ?? !!unref(accommodationId)),
    queryKey: ['accommodation', accommodationId] as const,
    staleTime: 24 * 60 * 60 * 1000, // 24h stale time
    queryFn({ signal, queryKey: [_, id] }) {
      return fetch<AccommodationFullDetails>(`/api/accommodations/${id}`, {
        signal,
      })
    },
    placeholderData: (previousData) => previousData,
  })
  if (!suspense) {
    onServerPrefetch(queryResult.suspense)
    return queryResult as Result
  }

  return new Promise<typeof queryResult>((resolve, reject) => {
    queryResult
      .suspense()
      .then(() => resolve(queryResult))
      .catch(reject)
  }) as Result
}

export function useAccommodationLicenseUpdate() {
  const fetch = useApiFetch()
  const queryClient = useQueryClient()
  let abortController: AbortController | null = null

  function abortUpdateHouseLicense() {
    if (abortController) {
      abortController.abort()
      abortController = null
    }
  }

  onUnmounted(abortUpdateHouseLicense)

  return useMutation({
    mutationFn({
      accommodationId,
      housingLicense,
    }: {
      accommodationId: string
      housingLicense: string
    }) {
      abortUpdateHouseLicense()
      abortController = new AbortController()
      const body: HouseLicenseUpdate = { housingLicense }

      return fetch(`/api/accommodations/${accommodationId}/license`, {
        method: 'PUT',
        signal: abortController.signal,
        body,
      })
    },
    onSuccess: (_, data) => {
      queryClient.invalidateQueries({
        queryKey: ['house-license', data.accommodationId],
      })
    },
  })
}

export function useAccommodationLicense(
  accommodationId: MaybeRef<string | undefined>,
) {
  const fetch = useApiFetch()
  return useQuery<string, FetchError>({
    queryKey: ['house-license', accommodationId] as const,
    queryFn({ signal, queryKey: [_, accommodationID] }) {
      return fetch<string>(`/api/accommodations/${accommodationID}/license`, {
        signal,
      }).catch((error) => {
        if (error.status === 404) return ''

        throw error
      })
    },
  })
}

const CONTEXT_ID = 'accommodationId' as const

type AccommodationCtx<T = string | undefined> = {
  accommodationId: Ref<UnwrapRef<T>>
  setAccommodationId(id: string | undefined): void
}

export function useAccommodationIdProvider<
  D extends string | undefined,
  T extends [D] extends [string] ? string : string | undefined,
>({ defaultValue }: { defaultValue?: D } = {}) {
  const accommodationId = ref<T>(defaultValue as T)
  const ctx: AccommodationCtx<T> = {
    accommodationId,
    setAccommodationId(id: string | undefined) {
      ;(accommodationId as Ref<string | undefined>).value = id
    },
  }
  provide(CONTEXT_ID, ctx)
  return ctx
}

export function useAccommodationIdContext<
  T extends string | undefined = string | undefined,
>() {
  return inject<AccommodationCtx<T>>(CONTEXT_ID, {
    get accommodationId() {
      console.error('No Accommodation context provided')
      return ref<T>(undefined as T)
    },
    setAccommodationId() {
      console.error('No Accommodation context provided')
    },
  })
}

export function useSelectedAccommodation(
  accommodationId: Ref<string | undefined>,
) {
  const { data: accommodations } = useAccommodationsQuery()

  return computed(() =>
    accommodations.value?.find(
      (accommodation) =>
        accommodation.accommodationCode === accommodationId.value,
    ),
  )
}
